import { Col, Container, ProgressBar, Row, Stack } from "react-bootstrap";
import FadeInUp from "../FadeInUp";
import styles from "./ResumeSection.module.css";

const ResumeSection = () => {
  return (
    <Stack className="section" id="resume">
      <Container>
        <Row>
          <Col>
            <FadeInUp>
              <p className="text-center mb-2">
                <span className="bg_section text-dark px-2">Resume</span>
              </p>
            </FadeInUp>
          </Col>
        </Row>

        <Row>
          <Col>
            <FadeInUp>
              <h2 className="text-1">About</h2>
            </FadeInUp>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <FadeInUp>
              <h2 className="text-7 fw-600 mb-4 pb-2">Who Am I?</h2>
              <div className={`${styles.border} ps-3`}>
                <div className="fs-4">As a self-driven freelancer,</div>
                <p className="mb-2">
                  I am always eager to push boundaries and continuously refine my skills. <br/>
                  I believe in clean, efficient code and delivering results that exceed expectations.
                </p>
                <hr className="my-4"/>
              </div>
            </FadeInUp>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <FadeInUp>
              <h2 className="text-1">A summary of My Resume</h2>
            </FadeInUp>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <FadeInUp>
              <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
              <div className={`${styles.border} ps-3`}>
                <div className="fs-4">Master's Degree</div>
                <p className="mb-2">
                  National Aerospace University - Kharkiv Aviation Institute, 2020-2021
                </p>
                <hr className="my-4"/>
              </div>
              <div className={`${styles.border} ps-3`}>
                <div className="fs-4">Bachelor's Degree</div>
                <p className="mb-2">
                  National Aerospace University - Kharkiv Aviation Institute, 2016-2020
                </p>
                <hr className="my-4"/>
              </div>
            </FadeInUp>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <FadeInUp>
              <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
              <div className={`${styles.border} ps-3`}>
                <div className="fs-4">1) Freelance Developer</div>
                <p className="mb-2">2021 - Present</p>
                <p className="text-muted">
                  Taking on freelance projects helped me grow as a developer and meet clients worldwide. Facing diverse
                  challenges allowed me to strengthen my problem-solving skills and deepen my understanding of
                  client-focused development.
                </p>
                <hr className="my-4" />
              </div>
            </FadeInUp>
            <FadeInUp>
              <div className={`${styles.border} ps-3`}>
                <div className="fs-4">2) Full-Stack JavaScript Engineer at MaybeWorks</div>
                <p className="mb-2">2019 - 2021</p>
                <p className="text-muted">
                  Joining MaybeWorks at a senior level broadened my responsibilities and offered invaluable insights
                  into complex system architectures and advanced development practices.
                </p>
                <hr className="my-4" />
              </div>
            </FadeInUp>
            <FadeInUp>
              <div className={`${styles.border} ps-3`}>
                <div className="fs-4">3) Full Stack Engineer</div>
                <p className="mb-2">2017 - 2019</p>
                <p className="text-muted">
                  At NixSolution, I started as a junior developer and gained extensive hands-on experience, progressing
                  quickly into full-stack development and managing project lifecycles.
                </p>
                <hr className="my-4" />
              </div>
            </FadeInUp>
          </Col>
        </Row>
        <FadeInUp>
          <h2 className="text-7 fw-600 mb-4 pb-2 mt-5">My Skills</h2>
        </FadeInUp>

        <Row className="gx-5">
          <Col md={6}>
            <FadeInUp>
              <Stack className="justify-content-between" direction="horizontal">
                <p className="fw-500 text-start mb-2">React</p>
                <span>95%</span>
              </Stack>
              <ProgressBar className={`${styles.progress_sm} mb-4`} now={95} />
            </FadeInUp>
          </Col>
          <Col md={6}>
            <FadeInUp>
              <Stack className="justify-content-between" direction="horizontal">
                <p className="fw-500 text-start mb-2">Angular</p>
                <span>90%</span>
              </Stack>
              <ProgressBar className={`${styles.progress_sm} mb-4`} now={90} />
            </FadeInUp>
          </Col>
          <Col md={6}>
            <FadeInUp delay={200}>
              <Stack className="justify-content-between" direction="horizontal">
                <p className="fw-500 text-start mb-2">Typescript</p>
                <span>90%</span>
              </Stack>
              <ProgressBar className={`${styles.progress_sm} mb-4`} now={90} />
            </FadeInUp>
          </Col>
          <Col md={6}>
            <FadeInUp delay={200}>
              <Stack className="justify-content-between" direction="horizontal">
                <p className="fw-500 text-start mb-2">Nest.js</p>
                <span>90%</span>
              </Stack>
              <ProgressBar className={`${styles.progress_sm} mb-4`} now={90} />
            </FadeInUp>
          </Col>
          <Col md={6}>
            <FadeInUp delay={400}>
              <Stack className="justify-content-between" direction="horizontal">
                <p className="fw-500 text-start mb-2">Django</p>
                <span>80%</span>
              </Stack>
              <ProgressBar className={`${styles.progress_sm} mb-4`} now={80} />
            </FadeInUp>
          </Col>
          <Col md={6}>
            <FadeInUp delay={400}>
              <Stack className="justify-content-between" direction="horizontal">
                <p className="fw-500 text-start mb-2">Mongo/Postgres</p>
                <span>90%</span>
              </Stack>
              <ProgressBar className={`${styles.progress_sm} mb-4`} now={90} />
            </FadeInUp>
          </Col>
        </Row>
      </Container>
    </Stack>
  );
};

export default ResumeSection;
